import { AvailableYear } from '../types/report'

export enum ReportViewMode {
	SLIDES = 'slides',
	ARTICLE = 'article',
}

function generateYearArray() {
	const currentYear = new Date().getFullYear()
	const startYear = 2018
	const years: AvailableYear[] = []

	for (let year = startYear; year <= currentYear; year++) {
		years.push(`${year}` as AvailableYear)
	}

	return years
}

export const availableYears: AvailableYear[] = generateYearArray()