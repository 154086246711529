import { ProductType } from '@/entities/ProductTypes'
import { DistrictsObjects } from '@/shared/types/common'
import { serverConfig } from '@/shared/config/serverConfig/serverConfig'
import { watch } from 'vue'

export enum ActivityName {
	PRODUCTION = 'production',
	RETAIL = 'retail',
	WHOLESALE = 'wholesale',
	IMPORT = 'import',
	EXPORT = 'export'
}

export const districtList: DistrictsObjects = {
	9: {
		code: '9',
		id: 9,
		name: 'ДНР, ЛНР, Запорожье, Херсон',
		color: 'rgba(0, 0, 0, 0.25)',
		regions: [
			{
				code: '93',
				name: 'ДНР',
				id: 93
			},
			{
				code: '94',
				name: 'ЛНР',
				id: 94
			},
			{
				code: '90',
				name: 'Запорожская область',
				id: 90
			},
			{
				code: '95',
				name: 'Херсонская область',
				id: 95
			}
		]
	},
	8: {
		code: '1',
		id: 8,
		name: 'Центральный округ',
		color: 'rgba(0, 0, 0, 0.15)',
		regions: [
			{
				code: '41',
				name: 'Ивановская область',
				id: 37
			},
			{
				code: '77',
				name: 'Ярославская область',
				id: 76
			},
			{
				code: '47',
				name: 'Костромская область',
				id: 44
			},
			{
				code: '37',
				name: 'Владимирская область',
				id: 33
			},
			{
				code: '78',
				name: 'Москва',
				id: 77
			},
			{
				code: '64',
				name: 'Рязанская область',
				id: 62
			},
			{
				code: '71',
				name: 'Тверская область',
				id: 69
			},
			{
				code: '69',
				name: 'Смоленская область',
				id: 67
			},
			{
				code: '73',
				name: 'Тульская область',
				id: 71
			},
			{
				code: '44',
				name: 'Калужская область',
				id: 40
			},
			{
				code: '53',
				name: 'Московская область',
				id: 50
			},
			{
				code: '49',
				name: 'Курская область',
				id: 46
			},
			{
				code: '70',
				name: 'Тамбовская область',
				id: 68
			},
			{
				code: '36',
				name: 'Брянская область',
				id: 32
			},
			{
				code: '60',
				name: 'Орловская область',
				id: 57
			},
			{
				code: '51',
				name: 'Липецкая область',
				id: 48
			},
			{
				code: '40',
				name: 'Воронежская область',
				id: 36
			},
			{
				code: '35',
				name: 'Белгородская область',
				id: 31
			}
		]
	},
	7: {
		code: '3',
		id: 7,
		name: 'Северо-Западный округ',
		color: 'rgba(0, 0, 0, 0.2)',
		regions: [
			{
				code: '82',
				name: 'Ненецкий автономный округ',
				id: 83
			},
			{
				code: '11',
				name: 'Республика Коми',
				id: 11
			},
			{
				code: '79',
				name: 'Санкт-Петербург',
				id: 78
			},
			{
				code: '33',
				name: 'Архангельская область',
				id: 29
			},
			{
				code: '10',
				name: 'Республика Карелия',
				id: 10
			},
			{
				code: '54',
				name: 'Мурманская область',
				id: 51
			},
			{
				code: '62',
				name: 'Псковская область',
				id: 60
			},
			{
				code: '56',
				name: 'Новгородская область',
				id: 53
			},
			{
				code: '39',
				name: 'Вологодская область',
				id: 35
			},
			{
				code: '50',
				name: 'Ленинградская область',
				id: 47
			},
			{
				code: '43',
				name: 'Калининградская область',
				id: 39
			}
		]
	},
	1: {
		code: '7',
		id: 1,
		name: 'Приволжский округ',
		color: 'rgba(0, 0, 0, 0.25)',
		regions: [
			{
				code: '19',
				name: 'Удмуртская Республика',
				id: 18
			},
			{
				code: '28',
				name: 'Пермский край',
				id: 59
			},
			{
				code: '22',
				name: 'Чувашская Республика',
				id: 21
			},
			{
				code: '13',
				name: 'Республика Марий Эл',
				id: 12
			},
			{
				code: '46',
				name: 'Кировская область',
				id: 43
			},
			{
				code: '75',
				name: 'Ульяновская область',
				id: 73
			},
			{
				code: '14',
				name: 'Республика Мордовия',
				id: 13
			},
			{
				code: '17',
				name: 'Республика Татарстан',
				id: 16
			},
			{
				code: '55',
				name: 'Нижегородская область',
				id: 52
			},
			{
				code: '02',
				name: 'Республика Башкортостан',
				id: 2
			},
			{
				code: '61',
				name: 'Пензенская область',
				id: 58
			},
			{
				code: '65',
				name: 'Самарская область',
				id: 63
			},
			{
				code: '66',
				name: 'Саратовская область',
				id: 64
			},
			{
				code: '59',
				name: 'Оренбургская область',
				id: 56
			}
		]
	},
	6: {
		code: '6',
		id: 6,
		name: 'Уральский округ',
		color: 'rgba(0, 0, 0, 0.15)',
		regions: [
			{
				code: '85',
				name: 'Ямало-Ненецкий автономный округ',
				id: 89
			},
			{
				code: '68',
				name: 'Свердловская область',
				id: 66
			},
			{
				code: '83',
				name: 'Ханты-Мансийский автономный округ - Югра',
				id: 86
			},
			{
				code: '48',
				name: 'Курганская область',
				id: 45
			},
			{
				code: '74',
				name: 'Тюменская область',
				id: 72
			},
			{
				code: '76',
				name: 'Челябинская область',
				id: 74
			}
		]
	},
	4: {
		code: '8',
		id: 4,
		name: 'Северо-Кавказский округ',
		color: 'rgba(0, 0, 0, 0.15)',
		regions: [
			{
				code: '21',
				name: 'Чеченская Республика',
				id: 20
			},
			{
				code: '05',
				name: 'Республика Дагестан',
				id: 5
			},
			{
				code: '16',
				name: 'Республика Северная Осетия-Алания',
				id: 15
			},
			{
				code: '07',
				name: 'Кабардино-Балкарская Республика',
				id: 7
			},
			{
				code: '06',
				name: 'Республика Ингушетия',
				id: 6
			},
			{
				code: '30',
				name: 'Ставропольский край',
				id: 26
			},
			{
				code: '09',
				name: 'Карачаево-Черкесская Республика',
				id: 9
			}
		]
	},
	2: {
		code: '2',
		id: 2,
		name: 'Южный округ',
		color: 'rgba(0, 0, 0, 0.2)',
		regions: [
			{
				code: '08',
				name: 'Республика Калмыкия',
				id: 8
			},
			{
				code: '34',
				name: 'Астраханская область',
				id: 30
			},
			{
				code: '38',
				name: 'Волгоградская область',
				id: 34
			},
			{
				code: '63',
				name: 'Ростовская область',
				id: 61
			},
			{
				code: '01',
				name: 'Республика Адыгея',
				id: 1
			},
			{
				code: '26',
				name: 'Краснодарский край',
				id: 23
			},
			{
				code: '91',
				name: 'Республика Крым',
				id: 91
			},
			{
				code: '92',
				name: 'Севастополь',
				id: 92
			}
		]
	},
	3: {
		code: '5',
		id: 3,
		name: 'Сибирский округ',
		color: 'rgba(0, 0, 0, 0.2)',
		regions: [
			{
				code: '72',
				name: 'Томская область',
				id: 70
			},
			{
				code: '57',
				name: 'Новосибирская область',
				id: 54
			},
			{
				code: '58',
				name: 'Омская область',
				id: 55
			},
			{
				code: '42',
				name: 'Иркутская область',
				id: 38
			},
			{
				code: '20',
				name: 'Республика Хакасия',
				id: 19
			},
			{
				code: '45',
				name: 'Кемеровская область',
				id: 42
			},
			{
				code: '04',
				name: 'Республика Алтай',
				id: 4
			},
			{
				code: '18',
				name: 'Республика Тыва',
				id: 17
			},
			{
				code: '27',
				name: 'Красноярский край',
				id: 24
			},
			{
				code: '23',
				name: 'Алтайский край',
				id: 22
			}
		]
	},
	5: {
		code: '4',
		id: 5,
		name: 'Дальневосточный округ',
		color: 'rgba(0, 0, 0, 0.15)',
		regions: [
			{
				code: '03',
				name: 'Республика Бурятия',
				id: 3
			},
			{
				code: '24',
				name: 'Забайкальский край',
				id: 75
			},
			{
				code: '52',
				name: 'Магаданская область',
				id: 49
			},
			{
				code: '15',
				name: 'Республика Саха (Якутия)',
				id: 14
			},
			{
				code: '84',
				name: 'Чукотский автономный округ',
				id: 87
			},
			{
				code: '25',
				name: 'Камчатский край',
				id: 41
			},
			{
				code: '29',
				name: 'Приморский край',
				id: 25
			},
			{
				code: '31',
				name: 'Хабаровский край',
				id: 27
			},
			{
				code: '32',
				name: 'Амурская область',
				id: 28
			},
			{
				code: '67',
				name: 'Сахалинская область',
				id: 65
			},
			{
				code: '81',
				name: 'Еврейская автономная область',
				id: 79
			}
		]
	}
}
export const productCategories = [
	{
		name: 'Этиловый спирт',
		id: ProductType.DISTILLATES,
		icon: require('@/shared/assets/icons/barrels.svg')
	},
	{
		name: 'Алкогольная продукция',
		id: ProductType.ALCOHOL,
		icon: require('@/shared/assets/icons/vodka.svg')
	},
	{
		name: 'Алкогольная продукция без учета напитков брожения',
		id: ProductType.ALCOHOL_WITHOUT_BEER,
		icon: require('@/shared/assets/icons/vodka.svg')
	},
	{
		name: 'Напитки брожения',
		id: ProductType.BEER,
		icon: require('@/shared/assets/icons/beer.svg')
	},
	{
		name: 'Спиртосодержащая продукция',
		id: ProductType.WITH_ALCOHOL,
		icon: require('@/shared/assets/icons/with-alcohol.svg')
	}
]
export const fullPageKeys = {
	license: 'FSKS8-END8I-5BKE8-T10J9-OWVUL',
	plugins: {
		scrollOverflow: 'MDBjMjFoY25SamIyMHViWE5yTG5KMXo0X0M3SWMyTnliMnhzVDNabGNtWnNiM2RTWlhObGRBPT1qR2I=',
		scrollHorizontally: 'TUljMjFoY25SamIyMHViWE5yTG5KMXZHX1k5OWMyTnliMnhzU0c5eWFYcHZiblJoYkd4NXhHOA==',
		resetSliders: 'UU1RVTVaUXhfOUVkY21WelpYUlRiR2xrWlhKemhJMQ==',
		fadingEffect: 'QzVRVTVaSWxfTVdkWm1Ga2FXNW5SV1ptWldOMHRNNw=='
	}
}

watch(serverConfig, () => {
	if (serverConfig.value?.environment === 'productive') {
		fullPageKeys.plugins.scrollHorizontally = 'eEFaV2RoYVhNdWNuVT01S19TaDJjMk55YjJ4c1NHOXlhWHB2Ym5SaGJHeDVySVc='
		fullPageKeys.plugins.fadingEffect = 'QzVRVTVaSWxfTVdkWm1Ga2FXNW5SV1ptWldOMHRNNw=='
		fullPageKeys.plugins.resetSliders = 'RzVRVTVaQTRfNGRkY21WelpYUlRiR2xrWlhKeld2Vw=='
		fullPageKeys.plugins.scrollOverflow = 'MzVaV2RoYVhNdWNuVT1uVV8yNmxjMk55YjJ4c1QzWmxjbVpzYjNkU1pYTmxkQT09dFVy'
	}
})

console.info(fullPageKeys)

export const reportPartsMapping = {
	production: 'Производство',
	import: 'Импорт',
	export: 'Экспорт',
	wholesale: 'Оптовый оборот',
	retail: 'Розница'
}
export const monthsInRusArray = [
	'январь',
	'февраль',
	'март',
	'апрель',
	'май',
	'июнь',
	'июль',
	'август',
	'сентябрь',
	'октябрь',
	'ноябрь',
	'декабрь'
]